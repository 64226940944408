header{
    padding: var(--s);
}

// ABOUT
.about{
    position: fixed;
    z-index: 2;
    top:0;
    right:0;
    width: 43%;
    background: white;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
}
.about .about-closer{
    display: block;
    padding: var(--s) 0;
}
.about p:not(:last-child){
    margin-bottom: 1em;
}
.about-active .about{
    opacity: 1;
    pointer-events: auto;
}

// PROJECTS LIST
.projects-list{
    position: fixed;
    z-index: 1;
    top:0;
    right: 0;
    width: 43%;
    padding: var(--s) var(--s) var(--s) 0;
    background: white;
}

.projects-list a:not(:last-child)::after{
    content: ' - ';
}
.projects-list a.p-active{
    opacity: 1;
}


// PROJECTS
section{
    position: relative;
}
.project-infos-holder{
    position: absolute;
    right:0;
    bottom: 0;
    width: 43%;
}
.project-infos{
    display: flex;
    align-items: flex-end;
    gap: 4rem;
    padding: var(--m) var(--m) 0 0;
}
.project-desc{
    width: 65%
}
.project-details{
    width: 30%
}

footer{
    padding:65vh var(--s) var(--s);
    display: flex;
    justify-content: space-between;
}
footer .copyright{
    width: 43%;
}

@media screen and (max-width:1024px) and (orientation: portrait) {
    header{
        width: 100%
    }

    .projects-list{
        display: none;
    }

    .about{
        width: 100%;
        padding: 0 var(--s) var(--s);
    }

    .project-infos-holder{
        position: relative;
        width: 100%;
        padding: 0 var(--s) var(--m);
    }
    .project-infos{
        flex-direction: column;
        gap: .5em;
        padding: 0;
    }
    .project-desc, .project-details{
        width: 100%
    }
    .project-details a{
        display: inline-block;
    }
    .project-details br{
        display: none;
    }

    footer{
        flex-direction: column;
    }
    footer .copyright{
        width: 100%;
    }
    
}