*{box-sizing: border-box; margin: 0; padding: 0; color: inherit; text-decoration: inherit; font-weight: inherit; font-size: inherit; list-style: inherit; outline:0;}
button:focus {outline:0;}

@font-face {
  font-family: NeueHaas;
  src: url('../NeueHaasGrotDisp-55Roman-Web.woff2') format("woff2");
}

:root{
  --s: .4rem;
  --m: .6rem;
  --l: 2rem;

  --swiperWidthSmall: 40vw;
  --swiperHeightSmall: calc(var(--swiperWidthSmall)*4/6.3);

  --swiperWidthBig: 100%;
  --swiperHeightBig: calc(101vh + var(--s));
  
  font-family: NeueHaas, serif;
  font-size: 1vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover, a.active{opacity: 1;}
a, a.active:hover{opacity: .35; transition: opacity .3s;}


@media screen and (max-width:1024px) and (orientation: portrait) {
  :root{
    font-size: 4vw;

    --swiperWidthSmall: 100%;
    --swiperHeightSmall: 60vw;
  }

  body{
    overflow-x: hidden;
  }

}